import React, { useState } from "react";
import { Listbox } from "@headlessui/react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Layout, SEO } from "../components";

const SuccessStoriesPage = ({ data }) => {
  const [filter, setFilter] = useState(null);
  const stories = data.allStrapiSuccessStories.edges;
  const trimDescription = (description) => {
    if (description.length > 160) {
      return description.substring(0, 157) + "...";
    }
    return description;
  };

  return (
    <Layout>
      <SEO
        title="Historias de éxito"
        description="Conoce nuestras historias de éxtio de persona y empresas que se ha suscrito a nuestro servicio de energía solar"
      />
      {/* Hero Start */}
      <div className="pt-24 bg-white">
        <div className="mx-auto px-8 max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
          <div className="text-center">
            <h1 className="tracking-tight font-bold text-gray-900 text-4xl md:text-5xl">
              Historias de éxito
            </h1>
            {/* <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Nulla vehicula tortor eu est vulputate tempus.
            </p> */}
          </div>
        </div>
      </div>
      {/* Hero End */}

      {/* Stories Start */}
      <div className="mx-auto bg-white pt-16 pb-8 lg:pt-16 lg:pb-16 px-8 max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
        <div className="mt-12 max-w-lg mx-auto grid gap-16 lg:grid-cols-2 lg:max-w-none">
          {stories.map((story) => (
            <Link to={`/historias-de-exito/${story.node.slug}`}>
              <div
                key={story.node.title}
                className="flex flex-col h-full rounded-lg border border-gray-200 overflow-hidden shadow-md transform transition duration-200 hover:shadow-lg hover:scale-101"
              >
                <div className="flex-shrink-0">
                  <GatsbyImage
                    alt={story.title}
                    className="h-48 w-full object-cover"
                    image={getImage(story.node.gallery[0].image.localFile)}
                  />
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <div className="block mt-2">
                      <p className="text-xl font-semibold text-gray-900">
                        {story.node.title}
                      </p>
                      <p className="mt-3 text-base text-gray-500">
                        {trimDescription(story.node.description)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      {/* Stories End */}
    </Layout>
  );
};

export default SuccessStoriesPage;

export const query = graphql`
  query {
    allStrapiSuccessStories(sort: { fields: [created_at], order: DESC }) {
      edges {
        node {
          id
          created_at(formatString: "LL")
          title
          slug
          description
          gallery {
            alt
            id
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 700)
                }
              }
            }
          }
        }
      }
    }
  }
`;
